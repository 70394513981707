import React, { useState } from 'react'
import Gabbung from '../../assets/hero-animation/gabung.png'
import Logo from '../../assets/logo.png'
import Charity from '../../assets/charity-fund.png'
import Marketing from '../../assets/marketing.png'
import LottieImage from '../lottieimage/LottieImage'
import Logo01 from '../../assets/newlogo/logo01.png'
import Logo02 from '../../assets/newlogo/logo02.png'
import logotext from '../../assets/logo-text.png'
import GameBox from '../gamebox/GameBox'


import PiizaHolder from '../../assets/games/pizza-holder.jpg'
import KapowKicks from '../../assets/games/kapow-kicks.jpg'
import MetaBlaster from '../../assets/games/meta-blaster.jpg'



const StarParalax = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <div className="starparalax-v2">
            {/* <div class="background">
                <img src={backgroundimg} alt="" />
            </div> */}
            <div className="hero-main-content">
                <div className="hero-sub">
                    <div className="container">
                        <div className="hero-header">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="hero-header-text">
                                        {/* <h1>SAFEPIZZA is now</h1> */}
                                        <img src={logotext} alt="" className="img-responsive" />
                                    </div>
                                    <div className="hero-games">
                                        <GameBox googlelink="https://play.google.com/store/apps/details?id=com.safepizza.pizzaholder"
                                            applelink="https://apps.apple.com/us/app/pizza-hodler/id1590647677"
                                            name="PIZZA HODLER"
                                            image={PiizaHolder}
                                        />

                                        <GameBox googlelink="https://play.google.com/store/apps/details?id=com.game.kapowkicks"
                                            applelink="https://apps.apple.com/app/kapow-kicks/id1605962710"
                                            name="Kapow Kicks"
                                            image={KapowKicks}
                                        />

                                        <GameBox googlelink="https://play.google.com/store/apps/details?id=com.game.metablaster"
                                            applelink="https://apps.apple.com/us/app/meta-blaster/id1610365790"
                                            name="Meta Blaster"
                                            image={MetaBlaster}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default StarParalax
