import React from 'react'
import AppStore from '../../assets/games/app-store.png'
import PlayStore from '../../assets/games/google-play.png'

const GameBox = (props) => {
    return (
        <>
            <div className="single-games">
                <div className="game-image">
                    <img src={props.image} alt="" />
                </div>
                <div className="game-text">
                    <h2>{props.name}</h2>
                    <a href={props.googlelink} target="_blank">
                        <img src={PlayStore} alt="" />
                    </a>
                    <a href={props.applelink} target="_blank">
                        <img src={AppStore} alt="" />
                    </a>
                </div>
            </div>

        </>
    )
}

export default GameBox
